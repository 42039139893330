.input-group {
  .range-time {
    width: 14rem;
    padding-left: 0.5rem;
    border: 1px solid #ced4da;
  }
  .footer {
    min-width: 5px !important;
  }
}

.flatpickr-calendar {
  .flatpickr-day.selected {
    border-color: var(--color-primary);
  }
  .flatpickr-day.selected,
  .flatpickr-monthDropdown-months,
  .flatpickr-monthDropdown-month,
  .flatpickr-month,
  .flatpickr-weekdays,
  .flatpickr-weekday {
    background: var(--color-primary);
    color: #fff;
  }
}

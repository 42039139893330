.battery {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 12px;
  transform: scale(var(--ggs, 1));
  border: 2px solid;
  border-color: #000;
  border-radius: 3px;
  margin-left: -3px;
  margin-top: 10px;
  margin-right: 0.5rem;

  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 6px;
    top: 1px;
  }

  &::before {
    right: -4px;
    border-radius: 3px;
    width: 4px;
    background: #000;
  }

  &::after {
    left: 1px;
    background: var(--bs-success);
  }

  &.b-100::after {
    width: 14px;
  }
  &.b-90::after {
    width: calc(14px * 0.9);
  }
  &.b-80::after {
    width: calc(14px * 0.8);
  }
  &.b-70::after {
    width: calc(14px * 0.7);
  }
  &.b-60::after {
    width: calc(14px * 0.6);
  }
  &.b-50::after {
    width: calc(14px * 0.5);
    background: var(--bs-warning);
  }
  &.b-40::after {
    width: calc(14px * 0.4);
    background: var(--bs-warning);
  }
  &.b-30::after {
    width: calc(14px * 0.3);
    background: var(--bs-danger);
  }
  &.b-20::after {
    width: calc(14px * 0.2);
    background: var(--bs-danger);
  }
  &.b-10 {
    border-color: #f00;
    &:after {
      width: calc(14px * 0.1);
      background: var(--bs-danger);
    }
    &:before {
      right: -4px;
      border-radius: 3px;
      width: 4px;
      background: rgb(255, 0, 0);
    }
  }
}

.table {
  .state-title {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .state-title-1 {
      position: relative;
      background: #4091d7 !important;
      color: #fff;
    }
    .state-title-2 {
      position: relative;
      background: #00ffff !important;
      color: #000;
    }
    .state-title-3 {
      position: relative;
      background: #fff700 !important;
      color: #000;
    }
    .state-title-4 {
      position: relative;
      background: #ffa500 !important;
      //color: rgb(255, 233, 233);
      color: #000;
    }
    .state-title-5 {
      position: relative;
      background: #ee0000 !important;
      color: #fff;
    }
    .title {
      font-weight: bold;
      text-align: center;
      padding: 0;
      font-size: 0.9rem;
      white-space: nowrap;
      line-height: 22px;
      // border-bottom: 0.5px dotted black;
    }
    .title2 {
      text-align: center;
      padding: 0;
      font-size: 0.6rem;
      white-space: nowrap;
      line-height: 18px;
    }
  }
}

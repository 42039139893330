.header-navs {
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);
  background-color: var(--color-primary) !important;

  img {
    height: 2rem;
    margin-right: 0.5rem;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
    white-space: nowrap;
  }
  .grow {
    flex-grow: 1;
  }
  .navbar-toggler {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    margin-right: 1rem;
  }
  #web-nav-user {
    background: var(--bg-gray-500);
    background: #adb5bd;
    padding: 0.25rem 0.7rem;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid transparent;
    &:hover {
      border-color: blue;
    }
    &::after {
      display: none;
    }
  }
  #header-user {
    .current-user {
      color: var(--color-primary);
    }
  }
}

.state {
  margin-right: 0.5rem;
  background: none;

  .bg-danger {
    color: #fff;
    background: #ee0000 !important;
  }
  .bg-caution {
    //color: rgb(255, 233, 233);
    color: #000;
    background: #ffa500 !important;
  }

  .bg-vigilance {
    color: #000;
    background: #fff700 !important;
  }
  .bg-warning {
    color: #000;
    background: #00ffff !important;
  }
  .bg-normal {
    color: #fff;
    background: #4091d7 !important;
  }
}

.table {
  .state {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 1.5rem;
    white-space: nowrap;
    text-align: center;
    span {
      border-radius: 0;
    }
    &.state-danger {
      background: #ee0000 !important;
      color: #fff;
    }
    &.state-caution {
      background: #ffa500  !important;
      //color: rgb(255, 233, 233);
      color: #000;
    }
    &.state-vigilance {
      background: #fff700 !important;
      color: #000;
    }
    &.state-warning {
      background: #00ffff !important;
      color: #000;
    }
    &.state-normal {
      background: #4091d7 !important;
      color: #fff;
    }
  }
}

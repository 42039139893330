.search {
  margin-left: 3%;
  margin-top: -7px;
  position: relative;
  width: 300px;
  .icon {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    // cursor: none;
  }
}
@media only screen and (max-width: 768px) {
  .search {
    width: 200px !important;
  }
}

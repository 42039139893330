.y-range {
  top: 10px;
  max-width: 50%;
  width: 30%;
  position: relative;
  .title {
    position: absolute;
    left: -120px;
    bottom: -13px;
    user-select: none;
  }
  .slider {
    position: relative;
    width: 100%;
    .slider__track,
    .slider__range,
    .slider__left-value,
    .slider__right-value {
      position: absolute;
    }
    .slider__track,
    .slider__range {
      border-radius: 3px;
      height: 5px;
    }

    .slider__track {
      background-color: #ced4da;
      width: 100%;
      z-index: 1;
    }

    .slider__range {
      background-color: #1c63b7;
      z-index: 2;
    }

    .slider__left-value,
    .slider__right-value {
      color: #0077ee;
      font-size: 12px;
      margin-top: -25px;
    }
    .slider__left-value {
      left: 6px;
    }

    .slider__right-value {
      right: -2px;
    }
  }
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .thumb {
    pointer-events: none;
    position: absolute;

    height: 0;
    width: 100%;
    outline: none;
    .thumb--zindex-3 {
      z-index: 3;
    }

    .thumb--zindex-4 {
      z-index: 4;
    }

    .thumb--zindex-5 {
      z-index: 5;
    }
  }
  .thumb::-webkit-slider-thumb {
    background-color: #a8a8ff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    z-index: 9999;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #0074ae;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #004993;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  button {
    position: absolute;
    top: -15px;
    left: 100%;
    width: 60px;
    margin-left: 7px;
  }
}

.slider {
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .slide-section {
    width: 100%;
    height: 100%;
    max-width: 740;
    max-height: 360;

    .image {
      //position: relative;
      top: 0;
      left: 0;
      max-width: 740;
      max-height: 360;
      border-radius: 5px;
    }

    .image2 {
      position: absolute;
      top:50%;
      left:50%;
      opacity: 0.7;
      border-color: green solid;
    }
    .right-arrow {
      position: absolute;
      top: 50%;
      right: -5px;
      font-size: 1.3rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
      :hover {
        font-size: 1.5rem;
        transition-duration: 1s ease;
      }
    }
    .button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
    }
    .left-arrow {
      position: absolute;
      top: 50%;
      left: -5px;
      font-size: 1.3rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
      :hover {
        font-size: 1.5rem;

        transition-duration: 1s ease;
      }
    }
    .slide {
      opacity: 0;
      transition-duration: 1s ease;
    }
    .slide-active {
      position: absolute;
      opacity: 1;
      //   transition-duration: 1s;
      transform: rotate(0.5turn);
      justify-content: center;
      align-items: center;
      left: 50%;

      transform: translateX(-50%);
    }
  }
}

.weekday-button {
  margin-right: 5px;
}

.weekday2-button {
  //margin-right: 6px;
	//display: flex;
	//padding-top: 5px;
	//padding-bottom: 5px;
	//margin-right: 3px;
	//margin-left: 2px;
	padding: 4px;
	margin: 5px;
	min-height: 1.5rem;
	max-width: 2.5rem;
	font-size: 0.95rem;
}


$primary: #1c63b7;

@import '~bootstrap/scss/mixins';

// @import '~bootstrap/scss/bootstrap';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';

@import '~bootstrap/scss/helpers';

@import '~bootstrap/scss/utilities/api';

@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';
// @import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/bootstrap-grid';

$grid-gutter-x: $grid-gutter-width;
$grid-gutter-y: 0;
.row {
  // @include make-row();

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(
    $grid-gutter-y * -1
  ); // stylelint-disable-line function-disallowed-list
  margin-right: calc(
    $grid-gutter-x * -0.5
  ); // stylelint-disable-line function-disallowed-list
  margin-left: calc(
    $grid-gutter-x * -0.5
  ); // stylelint-disable-line function-disallowed-list

  & > * {
    @include make-col-ready();
    padding-right: calc(
      $grid-gutter-x * 0.5
    ); // stylelint-disable-line function-disallowed-list
    padding-left: calc(
      $grid-gutter-x * 0.5
    ); // stylelint-disable-line function-disallowed-list
    margin-top: $grid-gutter-y;
  }
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--#{$variable-prefix}rows, 1), 1fr);
  grid-template-columns: repeat(
    var(--#{$variable-prefix}columns, #{$grid-columns}),
    1fr
  );
  gap: var(--#{$variable-prefix}gap, #{$grid-gutter-width});

  @include make-cssgrid();
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();

.color-success {
  color: var(--bs-success);
}
.color-warning {
  color: var(--bs-warning);
}
.color-danger {
  color: var(--bs-danger);
}
.color-secondary {
  color: var(--bs-secondary);
}

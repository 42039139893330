.screen-login {
  background-color: var(--color-primary);
  height: 100vh;

  a {
    display: inline-block;
    text-decoration: none;
    font-weight: 400;
  }

  h2 {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin: 40px 8px 10px 8px;
    margin: 2rem 1rem 1rem 1rem;
    color: var(--color-gray-1);
    &.inactive {
      color: var(--color-gray-1);
    }

    &.active {
      color: var(--color-text);
      border-bottom: 2px solid var(--color-primary);
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 2rem;
  }

  #formContent {
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    .input-group {
      margin-bottom: 1rem;
    }
    .btn {
      width: 10rem;
    }
  }

  #formFooter {
    background-color: var(--color-white);
    border-top: 1px solid #dce8f1;
    padding: 0rem;
    margin-top: 0.75rem;
    margin-right: 0.5rem;
    text-align: right;
    -webkit-border-radius: 0 0 1rem 1rem;
    border-radius: 0 0 1rem 1rem;
    cursor: pointer;
  }

  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -5%, 0);
      transform: translate3d(0, -5%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -5%, 0);
      transform: translate3d(0, -5%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  .underlineHover {
    &:after {
      display: block;
      left: 0;
      bottom: -10px;
      width: 0;
      height: 2px;
      background-color: var(--color-primary);
      content: '';
      transition: width 0.2s;
    }
    &:hover {
      color: var(--color-text);
    }
    &:hover:after {
      width: 100%;
    }
  }

  * {
    box-sizing: border-box;
  }
}

._table {
  // min-width: 33rem;
  z-index: 9999999999999999999;

  .function-button-area {
    .btn {
      margin-left: 0.75rem;
    }
  }

  &.nowrap {
    .table {
      white-space: nowrap;
      overflow-x: auto;
      background: #fff;
    }
  }

  ._pagging {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ._text {
      margin: 0.25rem;
      white-space: nowrap;
    }

    & > div {
      display: flex;

      .form-select {
        display: unset;
        width: unset;
        height: 2rem;
      }

      .pagination {
        margin-bottom: 0;
        .page-item {
          .page-link {
            color: var(--color-primary);
            padding: 0.125rem 0.25rem;
            min-width: 1.75rem;
            text-align: center;
            white-space: no-wrap;
          }
          &.active {
            .page-link {
              color: var(--color-white);
              background-color: var(--color-primary);
              border-color: var(--color-primary);
            }
          }
          &.disabled {
            .page-link {
              background: var(--bs-gray-200);
            }
          }
        }
      }
    }
  }

  ._table-container {
    max-width: 100%;
    overflow-x: auto;
  }

  thead {
    .data-装置名称 {
      width: 20%;
      min-width: 10px;
      position: relative;
    }

    .data-状態 {
      width: 25%;
      min-width: 160px;
      position: relative;
    }

    .data-状態表示 {
      width: 8%;
      min-width: 80px;
      position: relative;
    }

    .data-装置状態 {
      width: 25%;
      min-width: 160px;
      position: relative;
    }

    .data-wbgt暑さ指数 {
      position: relative;
    }
  }
  td {
    .data-状態表示 {
      width: 20%;
      position: relative;
    }
    position: relative;
  }

  .data-actions {
    color: var(--color-primary) !important;
    width: 1px;
    white-space: nowrap;
    svg {
      cursor: pointer;
      margin-right: 0.25rem;
    }
  }

  @import './Map.scss';

  @for $i from 1 through length($marker-colors) {
    $c: nth($marker-colors, $i);
    tbody tr:nth-of-type(#{length($marker-colors)}n + #{$i}) ._map-marker {
      color: $c;
    }
  }
  tbody {
    .data-actions {
      .danger {
        color: #cc0000 !important;
      }
      .caution {
        color: #ffa500 !important ;
      }
      .vigilance {
        color: #fff700 !important;
      }
      .warning {
        color: #00ffff !important;
      }
      .normal {
        color: #4091d7 !important;
      }
    }
  }
}

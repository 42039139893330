.toast-container {
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 99999999;

  .toast {
    width: 380px;
    .toast-header {
      color: #f4f4f5;
      background: #8f9399;
    }
    &.error {
      .toast-header {
        background: #fef0f0;
        color: #f63b3b;
        .btn-close-white {
          color: #f63b3b;
        }
      }
    }
    &.success {
      .toast-header {
        background: #f0f9eb;
        color: #67c23a;
      }
    }
    &.warning {
      .toast-header {
        background: #fdf6ec;
        color: #e39a2b;
      }
    }
  }
}

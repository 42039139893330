._modal {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-primary);
  }

  &.no-header {
    .modal-header {
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
  .card {
    border: none;
  }
  .input-group {
    margin-bottom: 0.5rem;
    .input-group-text {
      min-width: 12rem;
    }
  }

  ._page {
    .page-title {
      margin-left: 1rem;
    }
  }
  .button-save {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1200px) {
    .modal-dialog {
      max-width: 95%;
    }
  }
}

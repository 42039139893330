.chart {
  position: relative;
  .chart-title {
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    font-size: larger;
    font-weight: 700;
    position: relative;
  }
  .apexcharts-menu-item.exportCSV,
  .apexcharts-menu-item.exportSVG {
    display: none;
  }
  .chart-y-setting {
    position: absolute;
    display: flex;
    gap: 5px;
    width: 100%;
    bottom: -5px;
    button {
      margin-top: -5px;
      margin-left: 5px;
    }
  }
  .footer {
    position: absolute;
    bottom: -10px;
    left: 10px;
  }
  .startDate {
    position: absolute;
    bottom: 30px;
    left: 15px;
    font-size: smaller;
  }
  .endDate {
    position: absolute;
    bottom: 30px;
    right: -12px;
    font-size: smaller;
  }
  .averageValue {
    position: absolute;
    bottom: 0px;
    right: 20%;
    font-weight: bold;
    font-size: large;
  }
}

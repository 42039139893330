.screen-device-detail {
  .device-select {
    padding: 0 0.5rem;
    margin-left: 0.5rem;
  }

  #device-detail-status {
    .btn-upload {
      input[type='file'] {
        position: absolute;
        opacity: 0;
        z-index: 0;
        max-width: 100%;
        height: 100%;
        display: block;
      }
    }
    .card {
      width: 100%;
      max-width: 1440px;
      margin-bottom: 1rem;

      &._device-info-card {
        .card-body {
          .input-group {
            .input-group-text,
            .form-control {
              padding: 0;
              background: none;
              border: none;
            }
          }
        }
      }
      form {
        button {
          margin-right: 10px;
        }
      }
      .photo {
        position: relative;
        height: 100%;
        max-height: 360px;
      }
    }
  }
  .flatpickr-time {
    .numInputWrapper {
      width: 50px;
    }
  }
  .device-detail-thresh {
    #mobile-mode {
      visibility: hidden;
    }
    #label-title {
      color: var(--color-primary);
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-items: center;
      line-height: 30px;
    }
    .mail-list {
      .row {
        .col-3 {
          display: flex;
          justify-content: flex-end;

          margin-bottom: 3px;
          span {
            padding: 3px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .col {
          .btn {
            line-height: 0.75;
          }
          justify-content: space-between;
          border-left: 1px dashed #ccc;
          border-right: 1px dashed #ccc;
          border-bottom: 1px dashed #ccc;
        }
      }
    }
    .row {
      text-align: center;
      margin: 10px 15px;
      max-height: 35px;

      .col {
        margin-top: 3px;
        margin-bottom: 3px;
        display: flex;
        justify-content: space-around;
        .col {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        span:first-child {
          padding-top: 3px;
          margin-right: 5px;
        }
        .form-select {
          width: auto;
        }
        .time-picker {
          margin-top: 3px;
        }
        .form-check {
          margin-top: 5px;
        }
        // &:first-child {
        //   justify-content: flex-start;
        // }
        &:nth-child(2) {
          // justify-content: flex-start;
          .button {
            margin: auto 5px;
            width: 30px;
          }
          .time-picker {
            display: flex;
            max-width: 85px;
          }
        }

        .input-group {
          display: flex;
          flex-direction: row;
          input {
            max-width: 400px;
            margin-right: 2px;
          }
        }
      }
      .title-width {
        width: 30%;
      }
      .title-width2 {
        width: 40%;
      }
      .title-width3 {
        width: 50%;
      }
      .col-2,
      .col-sm-2,
      .col-sm-8,
      .col-sm-12 {
        margin-left: 0px;
        justify-content: flex-start;
        text-align: left;
      }

      .col-4,
      .col-2 {
        padding-top: 6px;
        &:nth-child(4) {
          display: flex;
          justify-content: center;
          margin: auto;
          padding-top: 0px;
          .time-selector {
            display: flex;
            justify-content: center;
            flex-direction: row;
            margin: auto;
            padding-top: 0px;
            border: 1px solid black;
            border-radius: 3px;
            padding: 0px 10px;
          }
          .time-picker {
            display: flex;
            max-width: 85px;
            border: none;
            width: 50px;
          }
        }
        span {
          padding: 0px 2px;
          padding-top: 1px;
          margin-left: -5px;
        }
      }
      .col-4,
      .col-2 {
        display: flex;
        justify-content: center;
        margin: auto;
        padding-top: 0px;
        // width: 33.3333333%;
        .title {
          padding: 0px 3px;
          margin: 0px 4px;
        }

        .title-2 {
          margin-left: 4px;
          padding-left: 5px;
        }
      }
      .value-set,
      .title-label {
        max-height: 35px;
        display: flex;
        justify-content: center;
        max-width: 300px;
        min-width: 160px;
        padding-top: 0px;
        gap: 5px;
        span {
          padding: 0px 10px;
        }
        .form-select {
          width: 85px;
        }
        .visible {
          visibility: hidden;
          opacity: 0;
        }
        div {
          width: 85px;
        }
      }

      .time-set {
        .time-picker {
          display: flex;
          max-width: 85px;
          text-align: center;
          border-radius: 3px;
          border: 1px solid black;
          padding-right: 5px;
        }
      }
      .label-set {
        justify-content: flex-end;
        padding-top: 5px;
        text-align: right;
        position: relative;
        .arrow {
          position: absolute !important;
          left: 65%;
          top: -2px;
          svg {
            top: 10px !important;
          }
        }
      }
      .enable-check {
        display: flex;
        justify-content: space-evenly;
        // width: 33.3333333%;
        .visible {
          visibility: hidden;
          opacity: 0;
          cursor: none;
        }
        .center-responsive {
          padding-top: 6px !important;
        }
      }
      .time-selector {
        display: flex;
        .time-picker {
          flex-direction: column;
          max-width: 85px;
        }
      }
      .set-mail-template {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        .col-sm-2 {
          text-align: end;
        }
        .value-set,
        .title-label {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
          .visible {
            visibility: hidden;
            opacity: 0;
          }
          div {
            width: 85px;
          }
        }
        .enable-check {
          justify-content: space-around;
          .button-up-download {
            display: flex;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
    .btn-check:focus {
      outline: 0px !important;
      -webkit-appearance: none;
      box-shadow: none !important;
    }
    .button-set {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 30px;
    }
    .tooltip {
      position: relative;
    }
  }

  .gps-setting {
    .button-set {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 30px;
    }
    .map-box {
			// color: var(--color-primary);
      margin-bottom: 10px;
    }
    #label-title {
      color: var(--color-primary);
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-items: center;
      line-height: 30px;
    }
    .mail-list {
      .row {
        .col-3 {
          display: flex;
          justify-content: flex-end;

          margin-bottom: 3px;
          span {
            padding: 3px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .col {
          display: flex;
          .btn {
            line-height: 0.75;
          }
          justify-content: space-between;
          border-left: 1px dashed #ccc;
          border-right: 1px dashed #ccc;
          border-bottom: 1px dashed #ccc;
        }
      }
    }
    .preview-button {
      display: flex;
      justify-content: space-around;
      width: 33.3333333%;
      .visible {
        visibility: hidden;
        opacity: 0;
        cursor: none;
      }
      justify-content: space-around;
      .button-up-download {
        display: flex;
        justify-content: center;
        gap: 5px;
      }
    }
  }

  .sf03wt2-setting {
    .button-set {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 30px;
    }
    // .map-box {
    //   margin-bottom: 10px;
    // }
    #label-title {
      color: var(--color-primary);
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-items: center;
      line-height: 30px;
    }
    .mail-list {
      .row {
        .col-3 {
          display: flex;
          justify-content: flex-end;

          margin-bottom: 3px;
          span {
            padding: 3px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .col {
          display: flex;
          .btn {
            line-height: 0.75;
          }
          justify-content: space-between;
          border-left: 1px dashed #ccc;
          border-right: 1px dashed #ccc;
          border-bottom: 1px dashed #ccc;
        }
      }
    }
    .preview-button {
      display: flex;
      justify-content: space-around;
      width: 33.3333333%;
      .visible {
        visibility: hidden;
        opacity: 0;
        cursor: none;
      }
      justify-content: space-around;
      .button-up-download {
        display: flex;
        justify-content: center;
        gap: 5px;
      }
    }
  }

  .nav-tabs {
    border-bottom: none;
    .nav-item {
      margin-left: 0.25rem;
      margin-top: 0.25rem;
      .nav-link {
        border-color: #dee2e6;
        border-radius: 0.25rem;
        color: var(--bs-gray-700);
        &.active {
          color: var(--color-primary);
          font-weight: bold;
        }
      }
    }
  }
  .tab-content {
    padding-top: 1rem;
  }
  .detail-map-container {
    height: calc(100vh - 17rem);
    display: flex;
    flex-direction: column;
    .leaflet-container {
      height: 100%;
    }
    .col {
      white-space: nowrap;
    }
  }
  .map-status {
		// height: calc(100vh - 17rem);
    height: 24.3rem;
    // height: 30rem;
  }
  .journey-nav-btns {
    max-width: 7.3rem;
		padding-bottom: 0.5rem;
  }
  .journey-dl-btn {
    max-width: 4.2rem;
		padding-bottom: 0.5rem;
  }
  .journey-btn {
    padding: 0 0.5rem;
    margin-left: 0.5rem;
  }
  #journeyList {
    overflow-y: auto;
    margin: 0;

    .journey-item {
      .card-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0.5rem;
      }
      margin-bottom: 0.75rem;
      cursor: pointer;
      .journey-location {
				width: 8rem;
        // width: 10rem;
        // width: 20rem;
      }

      &.journey-item-selected {
        background: var(--color-yellow-light);
      }
      &:hover {
        background: var(--color-primary-slight);
      }
    }
  }

  .geo-nav-btns {
    max-width: 7.3rem;
		padding-bottom: 0.5rem;
  }
  .geo-dl-btn {
    max-width: 4.2rem;
		padding-bottom: 0.5rem;
  }
  .geo-btn {
    padding: 0 0.5rem;
    margin-left: 0.5rem;
  }
  #geoList {
    overflow-y: auto;
    margin: 0;

    .geo-item {
      .card-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0.5rem;
      }
      margin-bottom: 0.75rem;
      cursor: pointer;
      .geo-location {
				width: 8rem;
        // width: 10rem;
        // width: 20rem;
      }

      &.geo-item-disabled {
        background: var(--color-gray-1);
      }
      &.geo-item-selected2 {
        background: var(--color-yellow-light2);
      }
      &.geo-item-selected {
        background: var(--color-yellow-light);
      }
      &:hover {
        background: var(--color-primary-slight);
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  #no-responsive {
    display: none;
    // visibility: hidden;
  }
  .arrow-no-responsive {
    display: none;
  }
  .label-set {
    text-align: center;
  }
  .time-setting {
    padding-bottom: 5px;
    border-bottom: 1px dashed #ccc;
  }
  .device-detail-thresh {
    .row {
      max-height: 350px !important;
    }
    .mail-template {
      .col-md-auto {
        justify-content: flex-start;
      }
    }
    .border-responsive {
      border: 1px solid black;
      border-radius: 5px;
      display: flex;
      margin: 10px 0px !important;

      .set-mail-template {
        display: inline-table !important;
        &:first-child {
          // display: inline-block;
          margin: 10px 0px;
        }
        .col-sm-1 {
          text-align: left !important;
        }
        .value-set {
          padding: 0 !important;
        }
        .col-4 {
          width: 100%;
          margin-top: 5px !important;
        }
      }
      .btn-group {
        margin-bottom: 5px;
      }
      .label-set {
        text-align: left !important;
      }
      .value-set {
        width: 100px !important;
        min-width: 150px !important;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-left: 0px !important;
        margin-left: -15px !important;
      }
      .time-set {
        width: 100px !important;
        min-width: none !important;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .title-label {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .enable-check {
        padding: 0 !important;
        width: 50%;
        margin-left: 20px !important;
        .center-responsive {
          padding-top: 6px !important;
        }
      }
      .time-picker {
        width: 85px !important;
        max-width: 100px !important;
      }
      .time-selector {
        margin-bottom: 5px !important;
      }

      .browser-mode {
        visibility: hidden;
      }
    }
    #mobile-mode {
      visibility: visible !important;
    }
  }
}

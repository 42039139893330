.sensor {
  margin-right: 0.5rem;
  background: none;

  .bg-warning {
    color: #000;
  }
  .bg-danger {
    color: #000;
  }
}

.table {
  .sensor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 2rem;

    span {
      border-radius: 0;
    }

    &.sensor-success {
      background: var(--bs-success);
    }
    &.sensor-warning {
      background: var(--bs-warning);
    }
    &.sensor-danger {
      background: var(--bs-danger);
    }

    .bg-purple {
      background: #8e44ad;
    }
  }
}

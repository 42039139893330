@import 'custom';

:root {
  --color-primary: #1c63b7;
  --color-primary-light: #5081d2;
  --color-primary-slight: #d3e0f7;
  --color-primary-dark: #002d71;
  --color-text: #0d0d0d;
  --color-gray-1: #cccccc;
  --color-white: #f6f6f6;
  --color-yellow-light: #fcfade;
	--color-yellow-light2:#e1e5b3;
  --bs-body-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, YuGothic,
    'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', Arial, 'メイリオ',
    Meiryo, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

@media (min-width: 200px) and (max-width: 575px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 50vh;
    z-index: 1;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 50vh;
    z-index: 1;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 55vh;
    z-index: 1;
  }
}

@media (min-width: 992px) and (max-width: 1439px){
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 60vh;
    z-index: 1;
  }
}

@media (min-width: 1440px) and (max-width: 1679px){
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 60vh;
    z-index: 1;
  }
}

@media (min-width: 1680px) and (max-width: 1891px){
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 60vh;
    z-index: 1;
  }
}

@media (min-width: 1892px) and (max-width: 1959px){
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 65vh;
    z-index: 1;
  }
}

@media (min-width: 1960px) {
  .container-fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .leaflet-container {
    width: 100%;
    height: 70vh;
    z-index: 1;
  }
}

#App {
  background: var(--color-gray-1);
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #body {
    flex-grow: 1;
    display: flex;
    .vertical-navs {
      background: var(--color-white);
      min-width: 12rem;
      display: none;
      .navbar-nav {
        flex-direction: column;
      }
      .nav-link {
        &._current {
          background: var(--bs-gray-500);
        }
        &:hover {
          background: var(--bs-gray-300);
        }
      }
    }
  }

  #main {
    max-width: 100%;
    flex-grow: 1;
    padding: 0.75rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--color-primary);
    }
  }
}

@media (min-width: 992px) {
  #web-nav {
    display: none !important;
  }
  #body {
    .navbar {
      display: block !important;
    }
  }
}
body {
  .hasTime {
    width: 100px;
    .flatpickr-time {
      width: 100px;
    }
  }
  .popover-header {
    min-width: 200px;
    z-index: 9999999999999999;
    font-weight: bold;
    .title-overlay {
      display: flex;
      justify-content: space-between;
      font-size: large;
    }
    .title-overlay-center {
      text-align: center;
    }
  }
  .title-overlay-danger {
    color: #fff;
    background: #ee0000 !important;
  }
  .title-overlay-caution {
    //color: rgb(255, 233, 233);
    color: #000;
    background: #ffa500 !important;
  }
  .title-overlay-vigilance {
    color: #000;
    background: #fff700 !important;
  }
  .title-overlay-warning {
    color: #000;
    background: #00ffff !important;
  }
  .title-overlay-normal {
    color: #fff;
    background: #4091d7 !important;
  }
  .title-overlay-yellowOrange {
    color: #000;
    background: #f0932b !important;
  }
  .title-overlay-redPink {
    color: #000;
    background: #eb4d4b !important;
  }
}

._noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.signal {
  height: 20px;
  width: 20px;
  // To show you the power of flexbox!
  display: flex;
  // Bars should be placed left to right
  flex-direction: row;
  // Evenly space the bars with space in between
  justify-content: space-between;
  // Sink the bars to the bottom, so they go up in steps
  align-items: baseline;
  margin-right: 0.5rem;
  gap: 1px;
  margin-top: 5px;

  .signal-bar {
    // 4 + 3 + 4 + 3 + 4 = 18px (as set above) 4px per bar and 3px margins between
    width: 4px;
    // All bars faded by default
    opacity: 30%;
    // Choose a color
    background: #888;

    // different heights for 3 different bars
    &:nth-child(1) {
      height: 20%;
    }
    &:nth-child(2) {
      height: 40%;
    }
    &:nth-child(3) {
      height: 60%;
    }
    &:nth-child(4) {
      height: 80%;
    }
    &:nth-child(5) {
      height: 100%;
    }
  }
  .warning-icon {
    // https://css.gg/danger
    box-sizing: border-box;
    position: relative;
    transform: scale(var(--ggs, 1));
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 40px;
    border-color: var(--bs-warning);
    background: var(--bs-warning);
    display: none;

    &::after,
    &::before {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      border-radius: 3px;
      width: 4px;
      background: #000;
      left: 6px;
    }
    &::after {
      top: 2px;
      height: 8px;
    }
    &::before {
      height: 2px;
      bottom: 2px;
    }
  }

  // Emphasize different bars depending on weak/medium/strong classes
  &.ss_0,
  &.NA {
    .warning-icon {
      display: block;
    }
    .signal-bar {
      display: none;
    }
  }

  &.ss_1 {
    .signal-bar {
      &:nth-child(1) {
        opacity: 1;
        background: var(--bs-danger);
      }
    }
  }

  &.ss_2,
  &.LIMIT {
    .signal-bar {
      &:nth-child(1),
      &:nth-child(2) {
        opacity: 1;
        background: var(--bs-danger);
      }
    }
  }
  &.ss_3,
  &.AVERAGE {
    .signal-bar {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        opacity: 1;
        background: var(--bs-warning);
      }
    }
  }
  &.ss_4,
  &.GOOD {
    .signal-bar {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        opacity: 1;
        background: var(--bs-success);
      }
    }
  }
  &.ss_5,
  &.EXCELLENT {
    .signal-bar {
      opacity: 1;
      background: var(--bs-success);
    }
  }
}

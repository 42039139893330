$marker-colors: var(--color-primary), var(--bs-danger), var(--bs-success),
  var(--bs-warning), #ff4838, #35bf6c, #084191, #ad0140, #259b9a, #361999,
  #162b32, #fb8122, #8da242, #0f2557, #d48166, #4ac6d2;

$marker-color-wbgt: (
  'danger': #ee0000,//var(--bs-danger),
  'caution': #ffa500,
  'vigilance': #fff700,
  'warning': #00ffff,
  'normal': #4091d7,
);

.leaflet-container {
  font: unset;
  ._map-marker {
    color: var(--color-primary);

    @for $i from 1 through length($marker-colors) {
      $c: nth($marker-colors, $i);
      &:nth-of-type(#{length($marker-colors)}n + #{$i}) {
        color: $c;
      }
    }
  }
  ._detail-map-marker {
    color: var(--color-primary);
  }
  ._geo-map-marker {
    color: var(--color-primary);

    @for $i from 1 through length($marker-colors) {
      //$c: nth($marker-colors, $i);
      //$c: nth($marker-colors, 1);
      $c: nth($marker-colors, 3);
      &:nth-of-type(#{length($marker-colors)}n + #{$i}) {
        color: $c;
      }
    }
  }
  .icon {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
  .danger {
    color: #cc0000 !important;
  }
  .caution {
    color: #ffa500 !important;
  }
  .vigilance {
    color: #fff700 !important;
  }
  .warning {
    color: #00ffff !important;
  }
  .normal {
    color: #4091d7 !important;
  }
  .leaflet-overlay-pane path.leaflet-interactive {
    @for $i from 1 through length($marker-colors) {
      //$c: nth($marker-colors, $i);
      //$c: nth($marker-colors, 1);
      $c: nth($marker-colors, 3);
      &:nth-of-type(#{length($marker-colors)}n + #{$i}) {
        stroke: $c;
      }
    }
  }
  .animated-icon{
    width: 20px;
    height: 20px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 50%;
    box-shadow: 0px 0px 4px white;
    //transition: all 1s
    //animation: markerAnimation infinite 5s !important;
  }
  // .leaflet-tile-pane {
  //   -webkit-filter: grayscale(100%);
  //   filter: grayscale(100%);
  // }

	.btnWrapper {
		//width: 100vw;
		display: flex;
		padding: 0px;
		margin: 2px;
		// justify-content: center;
	}

  @keyframes makerAnimation {
    0% {
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-right: -10px;
    }
    33% {
      width: 30px;
      height: 30px;
      margin-left: -15px;
      margin-right: -15px;
    }
    66% {
      width: 40px;
      height: 40px;
      margin-left: -20px;
      margin-right: -20px;
    }
    100% {
      width: 50px;
      height: 50px;
      margin-left: -25px;
      margin-right: -25px;
    }
  }
}

.me-auto {
  .device-group {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    .icons {
      margin-top: -2px;
    }
  }
  .sub-menu {
    :hover {
      background: var(--bs-gray-300);
    }
    .sub-nav-link {
      display: block;
      padding: 0.2rem 1rem;
      color: rgba(0, 0, 0, 0.55);
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    }
    ._current {
      background: var(--bs-gray-500);
    }
  }
}

._page {
  .page-title {
    margin-left: 0.25rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  form {
    .form-check,
    .form-check-inline {
      margin: 0.375rem 0.75rem;
      padding-left: 2em;
    }
  }

  .function-button-area {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.75rem;

    button {
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .form-control[readonly] {
    background: unset;
    outline: 0;
    box-shadow: unset;
    border: 0;
  }
  .input-group {
    margin-bottom: 0.5rem;
    .input-group-text {
      min-width: 12rem;
    }
  }
  .form-button-row {
    text-align: center;
    display: block;
    & > * {
      width: unset;
    }
  }

  .invisible {
    visibility: hidden;
  }
  .hidden {
    display: none;
  }
}
